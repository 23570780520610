import { lazy, useMemo } from "react";
const ReviewIndexRoute = lazy(
  () => import("./review-index-route-CU9u93kn.js").then((module) => ({
    default: module.ReviewIndexRoute
  }))
);
const useReviewIndexRoute = () => {
  return useMemo(
    () => ({
      "review-index-route": {
        component: ReviewIndexRoute
      }
    }),
    []
  );
};
export {
  useReviewIndexRoute
};
